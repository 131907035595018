<template>
  <div class="report-filters-sidebar">
    <SidebarContent>
        <template v-slot:content>
            <h2 v-if="dataset && dataset.title" class="underline left">
                {{ dataset.title }}
            </h2>
            <h2 v-else><div class="lds-dual-ring"></div></h2>
            <div class="filter-header">
                <DropDown
                  v-if="dataset"
                  placeholder="Chart"
                  class="chart-type white-dropdown"
                  :allowNoSelection="false"
                  :options="chartTypeOptions"
                  v-model="dataset.style.chartType"
                ></DropDown>
                <DropDown
                  v-if="dataset && dataset.style.chartType == 'line'"
                  :renderOptionLabelCallback="renderLineOptionsLabel"
                  :allowNoSelection="false"
                  placeholder="Line"
                  class="chart-line-type white-dropdown"
                  :options="chartLineTypeOptions"
                  v-model="dataset.style.lineType"
                ></DropDown>
                <DropDown
                  v-if="dataset"
                  :renderOptionLabelCallback="renderColorOptionLabel"
                  :allowNoSelection="false"
                  placeholder="Color"
                  class="chart-color white-dropdown"
                  :options="colorOptions"
                  v-model="dataset.style.color">
                </DropDown>
            </div>
            <div class="scrollable-container sidebar">
              <h3>Type</h3>
              <div class="filters">
                <div>
                    <DropDown
                      v-if="dataset"
                      :allowNoSelection="false"
                      v-model="dataset.sourceType"
                      :options="sourceTypeOptions"
                      @update:modelValue="onSourceTypeChanged()"
                    />
                </div>
                <div class="sourcetype-options-container" v-if="anyDrawingOptionEnabled(this.dataset?.sourceType)">
                  <span class="options-label">Drawing options</span>
                  <div class="options-container">
                    <div class="label-checkbox-container"  @click="onLogClick()"
                      v-if="logAxisAllowed(this.dataset?.sourceType)">
                      <label>Log</label><CheckBox class="checkbox" :checked="logChecked"></CheckBox>
                    </div>
                    <div class="label-checkbox-container" @click="onStackClick()"
                      v-if="stackingAllowed(this.dataset?.sourceType)">
                      <label>Stack</label><CheckBox class="checkbox" :checked="stackChecked"></CheckBox>
                    </div>
                    <div class="label-checkbox-container" @click="onRelativeClick()"
                      v-if="relativeAxisAllowed(this.dataset?.sourceType)">
                      <label>%</label><CheckBox class="checkbox" :checked="relativeChecked"></CheckBox>
                    </div>
                  </div>
                </div>
                <h3 class="filter">Filter</h3>
                <div v-for="(filterKey, index) in filterKeys" v-bind:key="index">
                  <label>
                    {{ filterLabels['views']['datasets']['filter'][filterKey]?.label || filterKey }}
                  </label>
                  <DropDown
                    :class="`filter-key-${filterKey}`"
                    noSelectionLabel="Reset filter"
                    noSelectionLabelSelected="No filters applied"
                    placeholder="Please select"
                    :emptyMultiSelectionValue="null"
                    :checkboxes="filterLabels['views']['datasets']['filter'][filterKey].multiselect"
                    :isMultiselect="filterLabels['views']['datasets']['filter'][filterKey].multiselect"
                    v-model="chosenOptions[filterKey]"
                    :options="filterOptions(filterKey)"
                    @update:modelValue="onFilterChanged($event, filterKey)"
                  />
                </div>

                <h3 class="filter">Grouping</h3>
                <div>
                    <DropDown
                      v-if="dataset"
                      v-model="dataset.grouping"
                      :options="groupingOptions"
                      @update:modelValue="onGroupingChanged($event)"
                    />
                </div>

              </div>
            </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"
import DropDown from "@/components/forms/DropDown2.vue"
import CheckBox from "@/components/ui/CheckBox.vue"
import ReportPlotMixin from "@/components/mixins/ReportPlotMixin.vue"
import { baseColors, palettes } from "@/store/reports.module.js"

export default {
  name: 'ReportFiltersSidebar',
  mixins: [ReportPlotMixin],
  components: {
      CheckBox,
      SidebarContent,
      DropDown,
  },
  props: {
  },
  computed: {
    report() {
      return this.$store.getters["reports/currentReport"]
    },
    viewIndex() {
      return this.$route.params.viewIndex || 0
    },
    datasetIndex() {
      return this.$route.params.datasetIndex || 0
    },
    view: {
      get() {
        return this.report?.views?.[this.viewIndex]
      },
      set(view) {
        this.report.views[this.viewIndex] = view
      }
    },
    dataset: {
      get() {
        return this.view?.datasets?.[this.datasetIndex]
      },
      set(dataset) {
        this.view.datasets[this.datasetIndex] = dataset
      }
    },
    logChecked() {
      return this.logAxisSelected(this.view, this.dataset?.sourceType)
    },
    stackChecked() {
      return this.stackingSelected(this.view, this.dataset?.sourceType)
    },
    relativeChecked() {
      return this.relativeAxisSelected(this.view, this.dataset?.sourceType)
    },
    filters() {
      return this.dataset?.filters || []
    },
    filterKeys() {
      return Object.keys(this.allFilterOptions?.[0] || [])
    },
    sourceTypeKeys() {
      return this.filterConfig?.map(f => f.type) || []
    },
    // isSaveButtonDisabled() {
    //   return !this.dataset?.sourceType
    // },
    chartTypeOptions() {
      return [
        {
          label: "Line",
          value: "line",
        },
        {
          label: "Bar",
          value: "bar"
        },
      ]
    },
    colorOptions() {
      return [
        { label: 'Field', value: 'palette:dutchField', isPalette: true },
        { label: 'Night', value: 'palette:riverNight', isPalette: true },
        { label: 'Classic', value: 'palette:otcClassic', isPalette: true },
        { label: 'Tab10', value: 'palette:tab10', isPalette: true },
        { label: 'Muted', value: 'palette:muted', isPalette: true },
        { label: 'HLS', value: 'palette:hls', isPalette: true },
        { label: 'C-Blind', value: 'palette:colorblind', isPalette: true },
        { label: 'Retro', value: 'palette:retroMetro', isPalette: true },
      ].concat(baseColors)
    },
    chartLineTypeOptions() {
      return [
        {
          label: "Solid",
          value: "line"
        },
        {
          label: "Dashed-dotted",
          value: "dashed-dotted-line"
        },
        {
          label: "Dashed (long)",
          value: "long-dashed-line"
        },
        {
          label: "Dashed (short)",
          value: "dashed-line"
        },
        {
          label: "Dotted",
          value: "dotted-line"
        },
        {
          label: "Points",
          value: "dotted"
        },
      ]
    },
    sourceTypeOptions() {
      return this.sourceTypeKeys.map( k => {
        return {
          label: this.filterLabels['views']['datasets']['types'][k]?.label || k,
          value: k
        }
      })
    },
    groupingOptions() {
      return this.filterKeys.map( k => {
        return {
          label: this.filterLabels['views']['datasets']['filter'][k]?.labelPlural || k,
          value: k,
        }
      })
    },
    filterLabels() {
      return this.$store.getters["config/labels"]
    },
    filterConfig() {
      return this.$store.getters["reports/filters"]
    },
    allFilterOptions() {
      return this.filterConfig?.find( f => f.type == this.dataset?.sourceType )?.options || []
    },
    chosenOptions() {
      return this.dataset.filters
    }
  },
  methods: {
    renderColorOptionLabel(option) {
      let bg = option.value;
      if (option.isPalette) {
        let key = option.value.replace("palette:", "")
        let c = palettes[key]
        bg = `conic-gradient(${c[1]} 0deg 90deg, ${c[3]} 90deg 180deg, ${c[2]} 180deg 270deg, ${c[0]} 270deg 360deg);`
      }
      return `<div class="dropdown-option-label"><div class="colorwell" style="background: ${bg}"></div>${option.label}</div>`
    },

    renderLineOptionsLabel(option) {
      const lineType = option.value;
      // values for the stroke-dasharray are slightly different from the lineBorderDash in
      // ReportView (much smaller space here)
      const strokeDasharrayValues = {
        "dotted": [2, 10],
        "dotted-line": [2, 2],
        "dashed-line": [4, 2.5],
        "long-dashed-line": [8, 5],
        "dashed-dotted-line": [7, 3, 2, 3],
      }
      const strokeDasharray = (strokeDasharrayValues[lineType] ?? [1, 0]).toString()
      const color = "black"
      return `<div class="dropdown-option-label">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24" style="width: 40px; height: 40px;">
        <path d="M 2 15 Q 10 5 20 15 T 38 15" stroke="${color}" stroke-width="2" fill="none"
          stroke-dasharray="${strokeDasharray}" /></svg></div>`
    },

    onCancel() {
      this.$router.push(`/reports/current`)
    },

    // Drawing options
    onStackClick() {
      this.toggleStacking(this.view, this.dataset?.sourceType)
      this.report.transient = true
    },
    onLogClick() {
      this.toggleLogAxis(this.view, this.dataset?.sourceType)
      this.report.transient = true
    },
    onRelativeClick() {
      this.toggleRelativeAxis(this.view, this.dataset?.sourceType)
      this.report.transient = true
    },

    filterOptionsByCriteria(options, criteria, currentKey) {
      return options.filter(option => {
        for (const key in criteria) {
          const criterion = criteria[key];
          const optionValue = option[key];

          if (key === currentKey) {
            return true
          }
          if (criterion === null || (Array.isArray(criterion) && criterion.length === 0)) {
            continue; // Treat null and empty arrays as wildcards
          } else if (Array.isArray(criterion)) {
            if (!criterion.includes(optionValue)) return false;
          } else if (optionValue !== criterion) {
            return false;
          }

        }
        return true
      });
    },

    narrowResult(array, currentKey) {
      // Check if there's a null value for any other key
      const nullValuesExist = array.some(item => {
        return item[currentKey] === null
      });

      if (nullValuesExist) {
        // If there's a null value, set all values for the key to null
        return array.map(item => {
          return { ...item, [currentKey]: null };
        });
      }

      // Group items by the currentKey key
      const groupedItems = array.reduce((grouped, item) => {
        (grouped[item[currentKey]] = grouped[item[currentKey]] || []).push(item);
        return grouped;
      }, {});

      // Filter groups to include only those where all items have the same value for the key
      const filteredGroups = Object.values(groupedItems).filter(
        group => group.every(item => item[currentKey] === group[0][currentKey] && item[currentKey])
      );

      // Flatten the filtered groups back into a single array
      const filtered_set = new Set([].concat(...filteredGroups))
      // Keep the order of the original array
      return array.filter(item => { return filtered_set.has(item) })
    },

    filterOptions(filterKey) {
      if (!this.dataset?.sourceType) {
        return []
      }
      let options = this.allFilterOptions
      let criteria = JSON.parse(JSON.stringify(this.chosenOptions))
      let filtered = this.filterOptionsByCriteria(options, criteria, filterKey)
      let narrowed = this.narrowResult(filtered, filterKey)
      let s = narrowed.filter( o => o != null).map(option => option[filterKey])
      s = s.filter( item => item != null)
      let valueLabels = this.filterLabels.views.datasets.filter?.[filterKey]?.values

      // Keep unique elements (keep order)
      return [...s]?.filter((val, idx, arr) => arr.indexOf(val) == idx)?.map( f => {
        return {
          label: valueLabels?.[f]?.label || f,
          value: f
        }
      })
    },
    onFilterChanged() {
      this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
        this.$store.commit("ui/error", err)
      })
    },
    onGroupingChanged() {
      this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
        this.$store.commit("ui/error", err)
      })
    },
    onSourceTypeChanged() {
      // reset the grouping if the the new source type doesn't have the grouping option
      if (!this.groupingOptions.find((elem) => {return elem.value == this.dataset.grouping}))
        this.dataset.grouping = null

      this.dataset.filters = Object.fromEntries(Object.entries(this.dataset.filters).filter(([key]) => this.filterKeys.includes(key)));
      this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
        this.$store.commit("ui/error", err)
      })
    },
  },
  watch: {
    "dataset.grouping"(oldValue, newValue) {
      if (oldValue == newValue && oldValue && newValue) {
          this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
          this.$store.commit("ui/error", err)
        })
      }
    }
  },
  created() {
    this.$store.dispatch("reports/fetchFilters")
  },
}
</script>

<style scoped>
.report-filters-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
h3.filter {
  text-align: left;
}
.dataset-tiles {
  width: 100%;
}
.dataset-top-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dataset-top-actions h3 {
  display: inline-block;
  flex-grow: 1;
  text-align: left;
}
label {
  text-align: left;
}
button.cancel {
  text-transform: uppercase;
  color: var(--c-greyish);
}
:deep(.sidebar-actions) {
  padding: 0 20px 0 0;
}
.filter-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.filter-header > *{
  margin-right: 10px;
}
h3 {
  text-align: left;
}
:deep(.dropdown.chart-type), :deep(.dropdown.chart-type .selectbox) {
  max-width: 120px;
  min-width: 120px;
}
:deep(.dropdown.chart-line-type), :deep(.dropdown.chart-line-type .selectbox) {
  max-width: 100px;
  min-width: 100px;
}

:deep(.dropdown.chart-color), :deep(.dropdown.chart-color .selectbox) {
  max-width: 150px;
  min-width: 150px;
}
:deep(.dropdown.chart-line-color .selectbox) {
  background: #f00;
}
:deep(.dropdown.chart-color .dropdown-option) {
  height: 24px;
  margin-left: 10px;
}
:deep(.dropdown.chart-color li) {
  height: 24px;
}

:deep(.dropdown.chart-color li:first-child) {
  margin-top: 10px;
}

h2 {
  text-align: left;
}

</style>

<style>
.loading .lds-roller div:after {
  color: #000;
}
.dropdown-option-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
div.colorwell {
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid var(--c-button-blue);
  border-color: var(--c-button-blue) transparent var(--c-button-blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown.filter-key-pzn .dropdown-option {
  height: 42px;
  font-size: 13px;
}

.sourcetype-options-container {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.options-container {
  display: inline-flex;
}

.label-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
}
.label-checkbox-container label {
  margin-bottom: 4px;
  color: var( --c-dark-blue);
}
.options-label {
  font-size: 14px;
  text-align: left;
}

</style>
