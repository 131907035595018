<template>
  <div class="chart-diagram">
    <div class="header">
      <div class="title">
        <h3>{{ this.title || "Analysis" }}</h3>
        <div class="subline">{{ isLoading ? 'Loading data ... ' : subtitle }}</div>
      </div>
      <slot name="header-actions">

      </slot>
      <button v-show="isZoomEnabled" :class="'ghost button rounded short' + (isZoomedIn ? ' active' : '')" @click="toggleZoom">Zoom</button>
    </div>
    <div class="chart-wrapper">
      <Bar v-if="false" />
      <Line id="chart" ref="chartRef" :data="chartData" :options="chartOptions" />
      <transition name="fade">
        <div class="chart-gradient" v-show="isZoomedIn"></div>
      </transition>
    </div>
    <div class="no-data" v-show="!hasData">No data available</div>
    <div class="footer">
      <div class="details" v-if="showLabelTexts">
        <div class="detail-label" :style="styles[index]" v-for="(label, index) in mediaspendingsLabels" v-bind:key="index">
          <inline-svg class="legend-icon" :src="require('@/assets/svg/icons/main_window/icon_legend_channels_16px.svg')" width="16" height="16"></inline-svg>
          Media spendings
          <span v-if="textLabels.length > 1">({{ String.fromCharCode(65 + index) }})</span>
        </div>
        <div class="detail-label" :style="styles[index]" v-for="(label, index) in textLabels" v-bind:key="index">
          <inline-svg class="legend-icon" :src="require('@/assets/svg/icons/main_window/icon_legend_line_16px.svg')" width="16" height="16"></inline-svg>
          {{ label }}
          <span v-if="textLabels.length > 1">({{ String.fromCharCode(65 + index) }})</span>
        </div>
        <div v-if="difference != undefined && !$store.state.ui.isFetchingChartData" class="difference">
          <inline-svg :src="require('@/assets/svg/icons/main_window/icon_difference_12x10px.svg')" width="12" height="10"></inline-svg>
          <span v-if="difference == 0">Difference:</span>
          <span class="difference-value">{{ formatCurrency(difference) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import { Line, Bar } from 'vue-chartjs'
import lodash from "lodash";

import UiMixin from '../mixins/UiMixin.vue';
import zoomPlugin from 'chartjs-plugin-zoom';
import { inject } from 'vue';

ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);

const defaultLegendClickHandler = ChartJS.defaults.plugins.legend.onClick;

var tooltipTitle, tooltipLabel

export default {
  name: "ChartDiagram",
  mixins: [UiMixin],
  components: { Bar, Line },
  props: {
    chartData: {
      type: Object
    },
    zoomRange: {
      type: Object
    },
    difference: {
      type: Number,
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    showLabelTexts: {
      type: Boolean
    },
    showMediaSpendingsLabels: {
      type: Boolean,
      default: true
    },
    showChartJSLegend: {
      type: Boolean,
      default: false
    },
    legendOnClickHandler: {
      type: Function
    },
    yAxes: {
      type: Array,
      default: () => [{}], // see defaultYAxisProps for configuration options
    },
  },
  setup() {
    tooltipTitle = inject('tooltipTitle');
    tooltipLabel = inject('tooltipLabel');
  },
  data: function () {
    return {
      isZoomedIn: false,
    };
  },
  methods: {
    defaultYAxisProps() {
      return {
        axisID: "y",
        title: null,
        autoDivide: true,
        tickLabelPrefix: "",
        tickLabelPostfix: "",
        position: "left",
      }
    },
    yAxesWithDefaults() {
      const default_ax = this.defaultYAxisProps()
      const result = this.yAxes.map((custom_ax) => ({...default_ax, ...custom_ax}))
      return result
    },
    toggleZoom() {
      this.isZoomedIn = !this.isZoomedIn
      this.updateZoom(this.zoomRange)
    },
    updateZoom(zoomRange) {
      let chart = this.$refs.chartRef.chart
      if (!chart) return
      if (this.isZoomedIn) {
        chart.zoomScale("y", zoomRange, "zoom")
      } else {
        chart.resetZoom()
      }
    },
    legendOnClick(e, legendItem, legend) {
      if (this.legendOnClickHandler) {
        this.legendOnClickHandler(e, legendItem, legend)
        return
      } else {
        defaultLegendClickHandler(e, legendItem, legend)
      }
    },
    yAxisConfig(ax_cfg) {
      // remove all args that have special treatment, the rest is simply an argument to the
      // ChartJs axis.

      /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
      const {
        axisID: _axisID, // unused
        autoDivide, tickLabelPostfix, tickLabelPrefix, title, relative,
        ...remainingArgs} = ax_cfg;

      const result_ax = {
        beginAtZero: true,
        title: {
          display: title?.length > 0,
          text: title ?? "",
          color: "#aeb4cb",
        },
        max: relative ? 1.0 : null,
        ticks: {
          color: "#aeb4cb",
          fontSize: 10,
          callback: function(value, _index, ticks) {
            if (relative) {
              // format such that the wide range of values for log scales is shown correctly
              return Number((100 * value).toPrecision(2)) + " %"
            }
            let prefix = tickLabelPrefix ?? ""
            let postfix = tickLabelPostfix ?? ""
            const max_tick_value = ticks[ticks.length - 1].value
            if (autoDivide) {
              if (max_tick_value >= 1_000_000_000) {
                value /= 1_000_000_000
                postfix = "B" + postfix
              } else if (max_tick_value >= 1_000_000) {
                value /= 1_000_000
                postfix = "M" + postfix
              } else if (max_tick_value >= 1000) {
                value /= 1000
                postfix = "k" + postfix
              }
            }
            if (postfix != "") postfix = " " + postfix
            if (prefix != "") prefix = prefix + " "
            return `${prefix}${value}${postfix}`
          }
        },
        grid: {
          borderColor: "#edeef3",
          display: true,
        },
        afterFit: function(/*scaleInstance*/) {
          //scaleInstance.width = 80;
        }
      }
      return lodash.merge(result_ax, remainingArgs)
    },
  },
  computed: {
    textLabels() {
      if (!this.chartData || !this.chartData.datasets) {
        return []
      }
      return this.chartData.datasets.map( dataset => dataset.label ).filter( l => l != undefined)
    },
    mediaspendingsLabels() {
      return this.showMediaSpendingsLabels ? this.textLabels : []
    },
    styles() {
      if (!this.textLabels) {
        return []
      }
      return this.chartData.datasets.map( dataset => {
        return { "--dot-color": dataset.borderColor }
      })
    },
    sanitizedChartData() {
      let data = this.chartData
      if (!data) {
        data = {
          labels: [],
          datasets: []
        }
      }
      if (!data.labels) {
        data.labels = []
      }
      if (!data.datasets) {
        data.datasets = []
      }
      return data
    },
    hasData() {
      return this.chartData.datasets?.length && this.chartData.labels?.length > 0
    },
    isZoomEnabled() {
      return this.zoomRange?.max
    },
    chartOptions() {
      const outer_this = this
      let yAxesById = {}
      for (const elem of Object.values(this.yAxesWithDefaults())) {
        yAxesById[elem.axisID] = this.yAxisConfig(elem)
      }

      return {
        animation: false,
        transitions: {
          resize: {
            animation: {
              duration: 0
            }
          }
        },
        interaction: {
          mode: 'nearest'
        },
        color: "#1e2466",
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 0,
        },
        plugins: {
          colors: {
            enabled: false,
          },
          title: {
            display: false,
            text: "",
            padding: {
              top: 10,
              bottom: 30,
            },
            align: "start",
          },
          legend: {
            display: this.showChartJSLegend,
            position: "bottom",
            align: "center",
            labels: {
              font: {
                size: 12,
                weight: "regular",
              },
              boxWidth: 13,
              boxHeight: 13,
            },
            onClick: this.legendOnClick
          },
          tooltip: {
            padding: 20,
            boxPadding: 10,
            callbacks: {
              title: tooltipTitle || function(context) {
                return "CW " + (context?.[0]?.label?.replace("CW ", "") || "")
              },
              label: tooltipLabel || function(context) {
                  let label = '';
                  if (context.parsed.y !== null) {
                      label += outer_this.formatCurrency(context.parsed.y);
                  }
                  return label;
              }
            }
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: false,
              },
              pinch: {
                enabled: false
              },
              mode: 'y',
              //overScaleMode: 'y',
            },
            pan: {
              enabled: false,
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            color: "#aeb4cb",
            stepSize: 4,
            ticks: {
              color: "#aeb4cb",
              fontSize: 10,
              // callback: function(value) {
              //   return `${value} CW`
              // }
            },
            grid: {
              borderColor: "#edeef3",
              display: false,
            },
          },
          ...yAxesById,
        }
      }
    },
    isLoading() {
      return this.$store.state.ui.isFetchingChartData
    }
  },
  watch: {
    zoomRange(newRange) {
      this.updateZoom(newRange)
    },
  }
}
</script>

<style scoped>
.chart-diagram {
  /* height: 470px; */
  width: 100%;
}
h3 {
  margin: 0 0 10px;
  text-align: left;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.subline {
  text-align: left;
  color: var(--c-deep-grey);
  font-size: 12px;
}
.no-data {
  color: var(--c-deep-grey);
  font-size: 12px;
  text-align: center;
  padding-bottom: 100px;
  display: flex;
  height: calc(100% - 100px);
  /* padding-left: 160px; */
  justify-content: center;
  align-items: center;
}
.details {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: center;
  margin-top: 7px;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 40px;
  width: 100%;
}
.title {
  flex-grow: 1;
}
.detail-label {
  font-size: 12px;
  margin: 0 20px;
  position: relative;
  text-align: left;
  /* white-space: nowrap; */
  /* max-width: 100px; */
}
.detail-label:last-child {
  margin: 0;
}
.detail-label .legend-icon {
  color: var(--dot-color);
  transform: translateY(2px);
  margin-right: 4px;
}
.detail-label:first-child {
  margin-left: 0;
}
.difference {
  font-size: 12px;
  white-space: nowrap;
  margin-top: 4px;
}
.difference-value {
  margin: 0 0 0 10px;
}
.chart-gradient {
  height: 65px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);;
  position: absolute;
  top: 0;
  left: 0;
}
#chart {
  position: absolute;
  /* margin-bottom: 40px; */
}
.chart-wrapper {
  position: relative;
  min-height: 340px;
  max-height: 340px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>
