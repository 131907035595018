
<template>
  <div :class="'report-view' + (showActive ? ' show-active' : '')">
    <JsonView :data="chartData" v-if="$store.getters['ui/debug']"/>
    <ChartDiagram
      :showChartJSLegend="true"
      class="report-chart"
      :title="title"
      :yAxes="yAxes"
      :chartData="chartData"
      :showLabelTexts="false"
      :showMediaSpendingsLabels="false"
      :subtitle="chartSubtitle"
      :legendOnClickHandler="legendOnClickHandler">
      <template #header-actions>
        <div class="date-picker-wrapper">
          <DatePicker
            :class="'from-date-picker' + (isFromDatePickerExpanded ? ' expanded' : '')"
            v-model="dateFrom"
            :type="datePickerGranularity"
            :placeholder="toDatePickerFormattedValue  || 'Start date'"
            :disabledDate="disabledDateFrom"
            :shortcuts="shortcutsDateTo"
            :formattedValue="toDatePickerFormattedValue"
            :dataweekStart="dataweekStart"
          />
          <DatePicker
            v-show="!isFromDatePickerExpanded"
            class="to-date-picker"
            v-model="dateTo"
            :type="datePickerGranularity"
            :placeholder="toDatePickerFormattedValue || 'End date'"
            :disabledDate="disabledDateTo"
            @open="onOpenDateToPicker"
            :formattedValue="toDatePickerFormattedValue"
            :dataweekStart="dataweekStart"
          />
          <DropDown
            bgColor="#fff"
            placeholder="Granularity"
            class="white-dropdown granularity"
            :allowNoSelection="false"
            :options="granularityOptions"
            :modelValue="reportViewData.granularity"
            @update:modelValue="onGranularityChanged"
          ></DropDown>
        </div>
        <div class="icon-button context-menu-button"
          @click.stop="$store.dispatch('ui/toggleContextMenu', { event: $event, data: reportViewData, menuItems: contextMenuItems })">
          <inline-svg
            :src="require('@/assets/svg/icons/reports/icon-dots.svg')" width="18" height="18">
          </inline-svg>
        </div>
      </template>
    </ChartDiagram>
    <ConfirmDialog v-if="viewToBeRenamed" title="Rename view">
        <template v-slot:message>Please enter a new name.</template>
        <template v-slot:content>
          <TextInput class="input-report-name" type="text" v-model="viewToBeRenamed.title" @keyup.enter="doRenameView" />
        </template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="onCancelRenameView" class="button ghost mediumsize rounded">Cancel</button>
            <button :disabled="isSaveViewButtonDisabled" @click.stop="doRenameView" class="button primary mediumsize rounded">Rename</button>
          </div>
        </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import ChartDiagram from "@/components/chart/ChartDiagram.vue";
import DatePicker from "@/components/ui/DatePicker.vue";
import ReportMixin from "../mixins/ReportMixin.vue";
import ReportPlotMixin from "../mixins/ReportPlotMixin.vue";
import DropDown from "../forms/DropDown2.vue";
import JsonView from "@/components/ui/JsonView.vue"
import useUI from '@/js/useUI.js'
import { provide } from 'vue';
import Util from "@/util.js"
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import TextInput from "@/components/ui/TextInput.vue"
import moment from "moment"
import { palettes } from "@/store/reports.module.js"

function normalizeDate(inputDate, type, granularity, dataweekStart) {
  const date = moment(inputDate);
  switch (granularity) {
    case 'dataweek': {
      const shiftDays = Util.dataweekConfig(dataweekStart).shiftToIsoWeek
      return type === 'start'
        ? date.add(shiftDays, 'd').startOf('isoWeek').add(-shiftDays, 'd').toDate()
        : date.add(shiftDays, 'd').endOf('isoWeek').add(-shiftDays, 'd').toDate();
    }
    case 'week':
      return type === 'start' ? date.startOf('isoWeek').toDate() : date.endOf('isoWeek').toDate();
    case 'month':
    case 'quarter':
    case 'year':
      return type === 'start' ? date.startOf(granularity).toDate() : date.endOf(granularity).toDate();
    default:
      throw new Error(`Unsupported granularity: ${granularity}`);
  }
}

export default {
  name: "ReportView",
  mixins: [ReportMixin, ReportPlotMixin],
  setup(props) {
    const tooltipTitle = (context) => {
      let ctx = context[0]
      let datasetIndex = ctx.datasetIndex
      if (datasetIndex === undefined) return "Information not available"

      let allHoverLables = props.reportViewData?.datasets?.reduce( (allHoverLables, dataset) => {
        dataset.chartData.hoverLabel.forEach( hoverLabel => {
          allHoverLables.push(hoverLabel)
        })
        return allHoverLables
      }, [])

      let label = allHoverLables[datasetIndex][ctx.dataIndex]
      return label
    }
    const tooltipLabel = () => {
      return ""
    }
    provide('tooltipTitle', tooltipTitle);
    provide('tooltipLabel', tooltipLabel);
  },
  components: {
    ChartDiagram,
    DatePicker,
    DropDown,
    JsonView,
    ConfirmDialog,
    TextInput,
  },
  props: {
    reportViewData: {
      type: Object
    },
    viewIndex: {
      type: Number,
      default: 0
    },
    showActive: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      viewToBeRenamed: null,
      onSelectPredefinedDateToRange: (value) => {
        if (value == "custom") {
          this.report.views[this.viewIndex].period.daterangePredefined = null
          this.updateShortcutStyles()
        } else {
          this.report.views[this.viewIndex].period.dateTo = null
          this.report.views[this.viewIndex].period.dateFrom = null
          this.report.views[this.viewIndex].period.daterangePredefined = value
          this.updateShortcutStyles()
        }
        this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
             this.$store.commit("ui/error", err)
        })
      },
    }
  },
  methods: {

    legendOnClickHandler(e, legendItem) {

      let view = this.$store.getters['reports/currentView']

      let count = 0
      let datasetIndex = 0
      let pos = view.datasets.reduce( (position, ds) => {
        for (var y=0; y < ds.chartData.y.length; y++) {
          if (count == legendItem.datasetIndex) {
            position = { datasetIndex, y }
          }
          count++
        }
        datasetIndex++
        return position
      }, {})

      if (pos.datasetIndex != undefined) {
        let datasetStyle = view.datasets[pos.datasetIndex].style
        if (datasetStyle.invisible) {
          return
        }
        if (!datasetStyle.y) {
          datasetStyle.y = {}
        }
        if (datasetStyle.y[pos.y] != undefined) {
          delete datasetStyle.y[pos.y].invisible
          if (Object.keys(datasetStyle.y[pos.y]).length == 0) {
            delete datasetStyle.y[pos.y]
          }
          if (Object.keys(datasetStyle.y).length == 0) {
            delete datasetStyle.y
          }
        } else {
          datasetStyle.y[pos.y] = {
            invisible: true
          }
        }
      }

    },
    onOpenDateToPicker() {
      this.$nextTick( () => {
        this.updateShortcutStyles()
      })
    },
    disabledDateFrom(date) {
      if (this.isDateRangePredefined) return true
      if (this.currentView.period.dateTo) {
        return (date >= new Date(Date.parse(this.currentView.period.dateTo + " 00:00:00")))
      }
      return false
    },
    disabledDateTo(date) {
      if (this.isDateRangePredefined) return true
      if (this.currentView.period.dateFrom) {
        return (date <= new Date(Date.parse(this.currentView.period.dateFrom + " 00:00:00")))
      }
      return false
    },
    onGranularityChanged(value) {
      this.report.views[this.viewIndex].granularity = value
      this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
        this.$store.commit("ui/error", err)
      })
    },
    onRenameView() {
      this.viewToBeRenamed = JSON.parse(JSON.stringify(this.reportViewData))
    },
    onCancelRenameView() {
      useUI(this.$store).unblockUI()
      this.viewToBeRenamed = null;
    },
    doRenameView() {
      if (this.viewToBeRenamed.title.length == 0) {
        return
      }
      this.report.views[this.viewIndex] = this.viewToBeRenamed
      this.report.save = true
      let mutation = this.report.transient ? "reports/updateTransient" : "reports/updateReport"
      this.$store.dispatch(mutation, this.report).then( () => {
        this.viewToBeRenamed = null
      }).catch( err => {
        this.$store.commit("ui/error", err)
      }).finally( () => {
        useUI(this.$store).unblockUI()
      })
    },
    setDate(newDate, key) {
      let date = newDate ? moment(newDate).format("YYYY-MM-DD") : null
      let view = this.report.views[this.viewIndex]
      view.period[key] = date ? moment(date).format("YYYY-MM-DD") : null
      this.report.views[this.viewIndex].period.daterangePredefined = null
      this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
        this.$store.commit("ui/error", err)
      })
      this.updateShortcutStyles()
    },
    updateShortcutStyles() {
      document.querySelectorAll('.mx-datepicker-sidebar > button').forEach( el => {
        el.style.color = "#73879c"
      })
      let placeholderEl = document.querySelector('.mx-datepicker-sidebar > button:nth-child(2)');
      if (placeholderEl) {
        placeholderEl.style.color = "var(--c-medium-grey)"
      }

      let daterangePredefined = this.currentView.period.daterangePredefined
      let idx = 0
      if (this.isDateRangePredefined) {
        idx = this.predefinedDateRanges.map( p => p.key).indexOf(daterangePredefined)
        if (idx > -1) {
          idx += 1
        } else {
          idx = 0
        }
      }
      let el = document.querySelector(`.mx-datepicker-sidebar > button[data-index = "${idx == 0 ? 0 : idx + 1}"]`)
        if (el && el.style) {
          el.style.color = "var(--c-button-blue)"
        }
    },
    datasetDimensionName(dataset) {
      const sourceType = dataset?.sourceType
      return this.labels.views.datasets.types[sourceType]?.dimension
    },
    getYAxesConfiguration(view) {
      const datasets = view?.datasets ?? []
      let sourceTypes = datasets.map(ds => ds.sourceType ?? "revenue")

      // make source types unique
      sourceTypes = sourceTypes.filter((element, index) => {
        return sourceTypes.indexOf(element) === index;
      })

      let axesBySourceType = {}
      for (const [i, sourceType] of sourceTypes.entries()) {
        const style = this.getSourceTypeStyle(view, sourceType)
        const relative = style?.relative
        const sourceTypeConfig = this.getSourceTypeConfig(sourceType)

        axesBySourceType[sourceType] = {
          axisID: `y${i}`,
          title: sourceTypeConfig?.yTitle ?? "",
          tickLabelPrefix: relative ? "" : sourceTypeConfig?.yTickLabelPrefix,
          tickLabelPostfix: relative ? " %" : sourceTypeConfig?.yTickLabelPostfix,
          position: (i == 0 ? "left" : "right"),
          yAutoDivide: true,
          stack: `y${i}`,
          stacked: true,
          type: style?.log ? "logarithmic" : "linear",
          relative: relative,
        }
      }

      return axesBySourceType
    },
    sumArrays(arr1, arr2) {
      return arr1.map( (a1val, idx) => { return a1val + arr2[idx] })
    },
    divideArrays(arr1, arr2) {
      return arr1.map( (a1val, idx) => { return arr2[idx] == 0 ? 0 : a1val / arr2[idx] })
    },
  },
  computed: {
    shortcutsDateFrom() {
      return this.predefinedDateRanges?.map( p => {
        return { text: p.label, onClick: this.onSelectPredefinedDateFromRange(p.key) }
      }) || null
    },
    shortcutsDateTo() {
      return [
        {
          text: "Custom date",
          onClick: () => this.onSelectPredefinedDateToRange("custom")
        },
        {
          text: "Predefined ranges",
          onClick: () => {}
        }
      ].concat(
          this.predefinedDateRanges?.map( p => {
            return { text: p.label, onClick: () => this.onSelectPredefinedDateToRange(p.key) }
          })
      ) || []
    },
    labels() {
      return this.$store.getters["config/labels"]
    },
    dataweekStart() {
      return this.$store.getters["config/dataweekStart"]
    },
    predefinedDateRanges() {
      return this.labels.views.period.daterangePredefined
    },
    currentView() {
      return this.report?.views?.[this.viewIndex] || null
    },
    report() {
      return this.$store.getters["reports/currentReport"]
    },
    datePickerGranularity() {
      let granularity = this.report.views[this.viewIndex].granularity
      switch (granularity) {
        case "quarter":
          return "month";
        default:
          return granularity
      }
    },
    isFromDatePickerExpanded() {
      return this.currentView?.period?.daterangePredefined != null
    },
    isDateRangePredefined() {
      return this.currentView?.period?.daterangePredefined != null
    },
    toDatePickerFormattedValue() {
      if (this.isDateRangePredefined) {
        return this.predefinedDateRanges.find(
          p => p.key == this.currentView?.period?.daterangePredefined
        )?.label ?? "Unknown range"
      }
      return null
    },
    dateFrom: {
      get() {
        return this.reportViewData ? this.reportViewData.period?.dateFrom : null
      },
      set(newDate) {
        this.setDate(
          normalizeDate(
            newDate, "start", this.reportViewData.granularity, this.dataweekStart
          ),
          "dateFrom"
        )
      }
    },
    dateTo: {
      get() {
        return this.reportViewData ? this.reportViewData?.period?.dateTo : null
      },
      set(newDate) {
        this.setDate(
          normalizeDate(
            newDate, "end", this.reportViewData.granularity, this.dataweekStart
          ),
          "dateTo"
        )
      }
    },
    isSaveViewButtonDisabled() {
      return this.viewToBeRenamed.title.length < 3
    },
    chartData() {
      let reportViewData = this.reportViewData ? JSON.parse(JSON.stringify(this.reportViewData)) : {}
      let labels = this.reportViewData.chartData?.x || []
      const ax_cfg = this.getYAxesConfiguration(this.reportViewData)

      let sums = {}
      // calculate sums for relative datasets
      reportViewData?.datasets?.filter( (ds) => {
        return reportViewData?.style?.sourceType?.[ds?.sourceType]?.relative && ds.chartData.y.length
      }).forEach( (ds) => {
        const values = ds.chartData.y.reduce((a, b) => this.sumArrays(a, b))
        if (sums[ds.sourceType] == null) {
          sums[ds.sourceType] = values
        } else {
          sums[ds.sourceType] = this.sumArrays(sums[ds.sourceType], values)
        }
      })

      let datasets = reportViewData?.datasets?.reduce( (datasetDataSets, dataset) => {
        // Line/Bar & colors
        let chartType = dataset.style?.chartType == "bar" ? "bar" : "line"
        let lineType = dataset.style?.lineType || "line"

        let baseColor = dataset.style.color || "hotpink"
        let colorMap
        if (baseColor.startsWith('palette:')) {
          colorMap = palettes[baseColor.replace('palette:', '')]
          if (!colorMap || colorMap.length == 0) {
            colorMap = ["hotpink"]
          }
        } else {
          colorMap = Util.createColorMap((dataset?.chartData?.y?.length || 0) + 1, baseColor, 0.65)
        }

        var color = null
        let datasetDataSet = dataset?.chartData?.y?.map( (y, index) => {
          color = Util.getNextItem(colorMap, color)
          const lineBorderDash = {
            "dotted-line": [2, 2],
            "dashed-line": [8, 6],
            "long-dashed-line": [16, 12],
            "dashed-dotted-line": [12, 6, 3, 6],
          }
          const lw = 1.5
          const isStacked = reportViewData?.style?.sourceType?.[dataset?.sourceType]?.stacked
          const relativeSum = sums[dataset.sourceType]

          return {
            hidden: dataset?.style?.invisible || dataset?.style?.y?.[index]?.invisible,
            label: dataset?.chartData?.title?.[index],
            lineTension: 0.3,
            type: chartType,
            pointRadius: chartType == 'line' ? (lineType == 'dotted' ? 3 : 0) : 0,
            pointHitRadius: 20,
            borderWidth: chartType == 'line' ? (lineType == 'dotted' ? 0 : lw) : lw,
            borderDash: chartType == 'line' ? lineBorderDash[lineType] ?? [] : [],
            borderColor: color,
            pointBackgroundColor: color,
            backgroundColor: color,
            data: relativeSum ? this.divideArrays(y, relativeSum) : y,
            stack: isStacked ? dataset?.sourceType : `s-${datasetDataSets.length}-${index}`,
            yAxisID: ax_cfg[dataset?.sourceType]?.axisID,
          }
        }) || []

        datasetDataSets = datasetDataSets.concat(datasetDataSet)

        return datasetDataSets
      }, []) || []

      let chartData = {
        labels: labels,
        datasets: datasets
      }
      return chartData;
    },
    title() {
      return this.reportViewData?.title || ""
    },
    yAxes() {
      const ax_cfg = this.getYAxesConfiguration(this.reportViewData)
      return Object.values(ax_cfg)
    },
    chartSubtitle() {
      return this.numberOfDatasetsTitle(this.reportViewData)
    },
    granularityOptions() {
      const granularities = this.$store.getters["config/granularities"] || []
      return granularities.map(g => {return {label: g.label, value: g.key}})
    },
    contextMenuItems() {
      return [
        {
          title: "Rename",
          handler: (reportViewData) => {
              useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
              this.onRenameView(reportViewData)
            },
        },
        {
          title: "Delete",
          handler: () => {
              useUI(this.$store).blockUI({ complete: true })
              this.$emit('view:delete', this.viewIndex)
            },
        },
      ];
    },
  },
  watch: {
  }
};
</script>

<style scoped>
.report-view {
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: var(--std-box-shadow);
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 7px;
  border: 1px solid transparent;
}
.report-view.show-active {
  cursor: pointer;
}
.report-view.show-active.active {
  border: 1px solid var(--c-button-blue);
}
.report-view-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: var(--c-button-blue);
  width: 100%;
  padding-top: 10px;
}

.report-chart {
  flex-grow: 1;
  padding: 20px 40px 40px 40px;
}
.date-picker-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.granularity-date-picker {
  margin-right: 10px;
}

:deep(.chart) {
  margin: 0;
}
.icon-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 24px;
  height: 24px;
}
.icon-button:hover {
  color: var(--c-link-blue);
}

.context-menu-button {
  position: absolute;
  top: 5px;
  right: 0px;
}
:deep(.chart-wrapper) {
  min-height: 480px;
  max-height: 480px;
}
:deep(.no-data) {
  display: none;
}

:deep(.chart-diagram .header) {
  position: relative;
  padding-right: 30px;
}
.from-date-picker {
  margin-right: 10px;
  transition: width 0.5s ease-in-out;
}
.to-date-picker {
  margin-right: 10px;
  /* transition: width 0.5s ease-in-out; */
}
.from-date-picker.expanded {
  width: 430px;
  /* transition: width 0.5s ease-in-out; */
}
:deep(.from-date-picker.expanded .mx-datepicker) {
  width: 430px;
  /* transition: width 0.5s ease-in-out; */
}

.granularity {
  min-width: 260px;
}

</style>

<style>

div.mx-datepicker-main.mx-datepicker-popup.undefined > div.mx-datepicker-sidebar > button:nth-child(2) {
  color: var(--c-medium-grey);
  font-style: italic;
}

</style>
